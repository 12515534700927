/*
----------------------------------------
Borger.dk theme
----------------------------------------
*/
$theme-colors: (
    "borgerdk": (
        100: #44831E,
        200: #3C5C22,
        300: #233614
    ),
    "background": (
        100: #f1f1f1
    )
);

$theme-color-primary: 'borgerdk-100';
$theme-color-primary-dark: 'borgerdk-200';
$theme-color-primary-darker: 'borgerdk-300';
$header-portal-background-color: 'background-100';
$header-portal-logo: 'logo-borgerdk.svg';
$header-portal-logo-height: 4.8rem;
$header-portal-compact-logo-width: 10rem;